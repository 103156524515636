<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import ContractDataService from "@/services/ContractDataService";

export default {
  name: 'ContractForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'contracts' },
    vueSlug: { default: 'contract' }
  },
  data() {
    return {
      dataService: ContractDataService,
      modelConfig: ContractDataService.getEditorConfig(),
      currentEntity: ContractDataService.getEntityDefinition(),
    }
  },
  setup() {
    return ModelForm.setup();
  },
  validations() {
    return {
      currentEntity: this.dataService.getValidationRules()
    }
  }
}
</script>
