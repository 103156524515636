<template>
  <div>
    <ContractForm
        @mainRecordLoaded="onMainRecordLoaded"
        :buttons="additionalFormButtons"
    />
    <ContractPositionList
        v-if="mainRecord.id"
        :hiddenColumns="getHiddenColumns()"
        :fixedFilters="getGridFixedFilters()"
        :renderKey="targetGridRenderKey"
        :gridActions="gridActions"
        @submitBatchAction="reloadSourceGrid()"
    />
  </div>
</template>

<script>
import ContractForm from "@/components/ContractForm";
import ContractPositionList from "@/components/ContractPositionList";
import ContractDataService from "@/services/ContractDataService";

export default {
  name: "ContractView",
  components: { ContractPositionList, ContractForm },
  data() {
    return {
      mainRecord: ContractDataService.getEntityDefinition(),
      sourceGridRenderKey: 0,
      targetGridRenderKey: 0,
      additionalFormButtons: [],
      gridActions: [
        {
          route: "/new-contract_position/" + this.$route.params.id,
          icon: "plus-circle",
          label: "Create Contract Position"
        }
      ],
    }
  },

  methods: {
    getGridActions() {
      return false;
    },
    reloadTargetGrid() {
      this.targetGridRenderKey++;
    },
    reloadSourceGrid() {
      this.sourceGridRenderKey++;
    },

    getHiddenColumns() {
      const filters = this.getGridFixedFilters();
      let columns = [];
      for (const [p] of Object.entries(filters)) {
        columns.push(p);
      }
      return columns;
    },

    getGridFixedFilters() {
      let obj = {
        contract_id: this.mainRecord.id
      }
      return obj;
    },

    onMainRecordLoaded(record) {
      this.mainRecord = record;
    }
  },
}
</script>
