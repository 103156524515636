<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import ContractPositionDataService from "@/services/ContractPositionDataService";

export default {
  name: 'ContractPositionList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'contract_positions' },
    vueSlug: { default: 'contract_position' }
  },
  data() {
    return {
      dataService: ContractPositionDataService,
      fieldConfig: ContractPositionDataService.getGridConfig(),
    }
  }
}
</script>
